import Vue from 'vue/dist/vue.esm'
import template from './notes_wysiwyg_template.slim'
import { EventBus } from '../../../event-bus'
import moment from 'moment'
import _ from "lodash";

Vue.component('notes-wysiwyg', {
  mixins: [template],
  props: {
    initialValue: null,
    mobile: null,
    disableMobileView: null,
    event: null,
    label: {default: ''},
    isPrivateNotes: false,
    cssClass: null
  },
  data() {
    return {
      hasFocus: false,
      value: '',
      watchInitialized: false,
      timestampModeActive: false,
      selectedTimestampFormat: 'HH:mm:ss',
      timestampFormats: ['HH:mm:ss', 'YYYY-MM-DD', 'hh:mm:ss A', 'MM/DD/YYYY'],
      isTouchSelecting: false,
      previousSelection: "",
      $popup: null,
      $popupButton: null,
      $inputContainer: null,
      $input: null,
      $askButton: null,
      $loading: null,
      $loadingText: null
    }
  },
  methods: {
    updateNotes() {
      const event = this.event || 'update-session-notes';
      this.$emit('input', this.$refs.notesInput.value)
      this.$emit('input', this.value)
      EventBus.$emit(event, this.value)
      if(event === 'update-session-notes' || event === 'updateObjectiveNote') {
        this.sendUpdateRequest();
      }
    },
    sendUpdateRequest: _.debounce(function () {
      this.$emit('saveNotes');
    }, 500),
    toggleFocus(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.hasFocus) {
        $('body').focus()
      }
      this.hasFocus = !this.hasFocus
    },
    adjustNotificationWidth (editor) {
      const editorWidth = editor.getContainer().offsetWidth;
      const notifications = document.querySelectorAll(".tox-notifications-container");
      notifications.forEach(notification => {
        notification.style.maxWidth = `${editorWidth}px`;
      });
    },
    toggleTimestampMode(editor) {
      this.timestampModeActive = !this.timestampModeActive;

      editor.notificationManager.open({
        text: this.timestampModeActive
          ? 'Auto Timestamp enabled. Press Enter to insert timestamp.'
          : 'Auto Timestamp disabled.',
        type: 'info',
        timeout: 3000
      });
      setTimeout(() => {
        this.adjustNotificationWidth(editor);
      }, 0);
    },
    changeTimestampFormat(format) {
      this.selectedTimestampFormat = format;
    },
    setupEditor(editor) {
      editor.on('init', () => {
        editor.execCommand('fontName', false, 'Montserrat, sans-serif');
        editor.selection.select(editor.getBody(), true);
        editor.selection.collapse(false);
        if (this.isPrivateNotes) {
          editor.contentDocument.body.style.backgroundColor = "#8A8C8F";
          editor.contentDocument.body.style.color = "#EFF3F3";
        }
      });

      editor.ui.registry.addButton('insertTimestamp', {
        icon: 'insert-time',
        tooltip: 'Insert Timestamp',
        onAction: () => {
          this.insertTimestamp(editor);
        },
      });

      editor.ui.registry.addMenuButton('timestampOptions', {
        tooltip: 'Timestamp Options',
        fetch: (callback) => {
          const items = [
            {
              type: 'togglemenuitem',
              text: 'Auto Timestamp',
              icon: this.timestampModeActive ? 'checkmark' : 'unselected',
              onAction: () => this.toggleTimestampMode(editor),
            },
            { type: 'separator' },
            ...this.timestampFormats.map((format) => {
              const realTimeExample = moment().format(format);
              return {
                type: 'menuitem',
                text: realTimeExample,
                icon: this.selectedTimestampFormat === format ? 'checkmark' : 'unselected',
                onAction: () => this.changeTimestampFormat(format),
              };
            })
          ];
          callback(items);
        }
      });

      editor.on('keydown', (e) => {
        if (this.timestampModeActive && e.key === "Enter") {
          e.preventDefault();
          editor.dom.remove(editor.getBody().querySelectorAll('[data-mce-bogus="1"]'));
          const timestamp = moment().format(this.selectedTimestampFormat);
          editor.execCommand('mceInsertContent', false, `<p>${timestamp}</p>`);
        }
      });

      editor.on('touchstart', () => {
        this.isTouchSelecting = true;
      });
      editor.on('touchend', () => {
        if (this.isTouchSelecting) {
          this.isTouchSelecting = false;
          this.triggerTextSelection(editor);
        }
      });
      editor.on('selectionchange', () => {
        if (!this.isTouchSelecting) {
          this.triggerTextSelection(editor);
        }
      });
    },
    insertTimestamp(editor) {
      editor.dom.remove(editor.getBody().querySelectorAll('[data-mce-bogus="1"]'));
      const timestamp = moment().format(this.selectedTimestampFormat);
      editor.execCommand('mceInsertContent', false, timestamp);
    },
    triggerTextSelection (editor) {
      if ($(editor.targetElm).hasClass("tinymce-with-rewrite")) {
        const selectedText = editor.selection.getContent({ format: "html" });
        if (this.previousSelection !== selectedText) {
          this.previousSelection = selectedText;

          if (selectedText) {
            const startIndexOfSelectedText = this.getStartIndexOfSelectedText(editor);
            if (startIndexOfSelectedText !== -1) {
              this.toggleRewritePopup(true, editor, selectedText, startIndexOfSelectedText);
            }
          } else {
            this.toggleRewritePopup(false);
          }
        }
      }
    },
    getStartIndexOfSelectedText: function(editor) {
      const marker = "###SelectionStartedHere###";
      const range = editor.selection.getRng();
      const tempMarker = document.createTextNode(marker);
      range.insertNode(tempMarker);

      const contentWithMarker = editor.getContent({ format: "html" });
      tempMarker.parentNode.removeChild(tempMarker);
      editor.selection.setRng(range);

      const markerIndex = contentWithMarker.indexOf(marker);
      if (markerIndex === -1) {
        return -1;
      }

      const editorBody = editor.getBody();

      function clearSelection (event) {
        const selectionRange = editor.selection.getRng();
        if (selectionRange) {
          const startContainer = selectionRange.startContainer;
          const endContainer = selectionRange.endContainer;

          if (event.target === startContainer.parentNode || event.target === endContainer.parentNode) {
            editor.selection.collapse();
          }
        }
      }

      $(editorBody).off("touchstart").on("touchstart", clearSelection);

      return markerIndex;

    },
    toggleRewritePopup (show, editor = null, selectedText = "", startIndexOfSelectedText = -1) {
      if (!this.$loading.is(":visible")) {
        if (show && startIndexOfSelectedText !== -1) {
          this.rewriteNotes(editor, selectedText, startIndexOfSelectedText);
        } else {
          this.$popup.fadeOut();
        }
      }
    },
    rewriteNotes (editor, selectedText, startIndexOfSelectedText) {
      const popupPosition = this.calculatePopupPosition(editor);
      if (popupPosition) {
        this.$popupButton.show();
        this.$inputContainer.hide();
        this.$inputContainer.removeClass("active");
        this.$loadingText.text("Thinking...");
        this.$loading.hide();

        this.$popup.css({
          "top": `${Number.parseInt(popupPosition.top) + 20}px`
        }).fadeIn();
      }

      this.$askButton.off("click").on("click", () => {
        this.callRewriteNoteAPI(editor, selectedText, startIndexOfSelectedText);
      });

      this.$input.off("keydown").on("keydown", (event) => {
        if (event.key === "Enter" || event.keyCode === 13) {
          this.callRewriteNoteAPI(editor, selectedText, startIndexOfSelectedText);
        }
      });
    },
    calculatePopupPosition (editor) {
      let position = null;
      const selection = editor.selection;
      const range = selection.getRng();

      if (range && !range.collapsed) {
        const tempRange = range.cloneRange();
        tempRange.collapse(false);
        const rect = tempRange.getBoundingClientRect();

        if (rect) {
          const iframe = editor.iframeElement;
          const iframeRect = iframe.getBoundingClientRect();
          const top = rect.bottom + iframeRect.top + window.scrollY;

          position = { top }
        }
      }

      return position;
    },
    callRewriteNoteAPI (editor, selectedText, startIndexOfSelectedText) {
      this.$popupButton.hide();
      this.$inputContainer.removeClass("active");
      this.$inputContainer.hide();
      this.$loading.show();

      editor.mode.set("readonly");
      editor.dom.setStyles(editor.dom.select("body"), {
        opacity: "0.5"
      });

      const data = {
        whole_note: editor.getContent({ format: "html" }),
        selected_text: selectedText,
        user_prompt: this.$input.val()
      }
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));

      $.ajax({
        url: `/rewrite_notes`,
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        success: (response) => {
          this.simulateStreamingText(editor, response.old_phrase, startIndexOfSelectedText, response.new_phrase.trim());
        },
        error: (xhr) => {
          if (xhr.responseJSON.code === 401) {
            this.$popup.hide();
          } else {
            this.$loading.hide();
            this.$inputContainer.show();
            this.$inputContainer.addClass("active");
          }

          editor.dom.setStyles(editor.dom.select("body"), {
            opacity: "1"
          });
          editor.mode.set("design");

          editor.notificationManager.open({
            text: `[ERROR] ${xhr.responseJSON.message}`,
            type: "error",
            timeout: 3000,
          });
          setTimeout(() => {
            this.adjustNotificationWidth(editor);
          }, 0);
        }
      });
    },
    simulateStreamingText (editor, oldPhrase, startIndexOfOldPhrase, newPhrase) {
      this.$loadingText.text("Writing...");

      const speed = Math.ceil(3000 / newPhrase.length);
      const content = editor.getContent({ format: "html" });
      const prefixContent = content.slice(0, startIndexOfOldPhrase);
      const suffixContent = content.slice(startIndexOfOldPhrase + oldPhrase.length);

      editor.setContent(prefixContent + suffixContent);

      let currentIndex = 0;
      const interval = setInterval(() => {
        const animatedPhrase = newPhrase.slice(0, currentIndex + 1);
        const updatedContent = prefixContent + animatedPhrase + suffixContent;

        editor.setContent(updatedContent);
        editor.selection.select(editor.getBody(), true);
        editor.selection.collapse(false);

        currentIndex++;

        if (currentIndex >= newPhrase.length) {
          this.$loading.hide();
          this.$input.val("");
          this.$popup.fadeOut();

          editor.setContent(prefixContent + newPhrase + suffixContent);
          editor.save();

          clearInterval(interval);
          editor.dom.setStyles(editor.dom.select("body"), {
            opacity: "1"
          });
          editor.mode.set("design");
        }
      }, speed);
    }
  },
  mounted() {
    if (this.initialValue) {
      this.value = this.initialValue
    }

    this.$popup = $("#rewrite-notes-popup");
    this.$popupButton = $("#rewrite-notes-popup-button");
    this.$inputContainer = $("#rewrite-notes-input-container");
    this.$input = $("#rewrite-notes-input");
    this.$askButton = $("#rewrite-notes-ask-button");
    this.$loading = $("#rewrite-notes-loading");
    this.$loadingText = $("#rewrite-notes-loading-text");

    this.$popupButton.off("click").on("click", () => {
      this.$popupButton.hide();
      this.$inputContainer.show();
      this.$inputContainer.addClass("active");
      this.$input.val("").focus();
      this.$loading.hide();
    });

    this.$popup.hide();
  },
  watch: {
    initialValue(newInitialValue) {
      this.value = newInitialValue;
    },
  },
})
